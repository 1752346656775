.navbar {
    border-bottom: 2px solid #0A4650;
    padding-right: 2rem;
}

.navbar-user {
    display: flex;
    align-items: center;
}

.navbar-user h6 {
    margin: 0;
    padding-right: 0.5rem;
    color: #0A4650;
}

.navbar-user .down-arrow {
    font-size: 1.2rem;
}
